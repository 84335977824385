import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", { attrs: { "after-close": _vm.afterModalClose, "title": "View Finished Products" }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
    return [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onDoneClick } }, [_vm._v("Done")])];
  }, proxy: true }]), model: { value: _vm.visible, callback: function($$v) {
    _vm.visible = $$v;
  }, expression: "visible" } }, [_c("a-table", { attrs: { "bordered": "", "columns": _vm.columns, "data-source": _vm.resourceList, "pagination": false, "row-key": "itemCode" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ViewFinishedProducts",
  inject: ["apiUrl", "crud", "redirectRoute"],
  data() {
    return {
      customUrl: `${this.apiUrl}/sterilization/britestacks/${this.$route.params.id}/finished-goods`,
      visible: false,
      columns: [
        {
          key: "ItemCode",
          dataIndex: "itemCode",
          title: "Item Code"
        },
        {
          key: "Description",
          dataIndex: "description",
          title: "Description"
        },
        {
          key: "ListCatalog",
          dataIndex: "listCatalog",
          title: "List Catalog"
        }
      ]
    };
  },
  computed: {
    resourceList() {
      return this.crud.getList();
    }
  },
  async created() {
    await this.crud.fetchList(this.customUrl);
  },
  async mounted() {
    this.visible = true;
  },
  methods: {
    afterModalClose() {
      this.$router.push(this.redirectRoute);
    },
    onDoneClick() {
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ViewFinishedProducts = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "sterilization.britestacks.finished-products", "api-url": _vm.apiUrl, "redirect-route": "/sterilization-specifications/britestacks/" + _vm.britestackId, "resource-id-name": "itemCode" } }, [_c("view-finished-products")], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    ViewFinishedProducts
  },
  data() {
    const { id } = this.$route.params;
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      britestackId: id
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
